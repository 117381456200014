import './Picture.css'
export const Picture = () =>
{
    return (
        <div className="imageDisplay">
            <img src='https://www.streamingrant.com/wp-content/uploads/2021/09/Best-Movies-on-Amazon-Prime.jpg' alt='prime' />


        </div>

    );
};